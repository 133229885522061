// @TODO this should be using an interface publicly exported from @artnetworldwide/library-ui rather
// than referencing the StyledImageProps interface from @artnetworldwide/library-ui/elements/media/Image directly.
// But first @artnetworldwide/library-ui needs to be refactored (for one thing, StyledImageProps isn't the best name for this
// -- it's actually different from ImageProps and that should be more clear.)
import { StyledImageProps } from '@artnetworldwide/library-ui/elements/media/Image'

/**
 * passing `4` will allow for smaller image sizes that fit
 * 4 item grid.  Right now this only supports `3` or otherwise sets the CSS
 * for a 4 item grid
 */
export const gridCardOptions = (maxGridSize?: 4) => {
    const imageHeight = maxGridSize && maxGridSize === 4 ? '15vw' : '20vw'
    const imageMaxHeight = maxGridSize && maxGridSize === 4 ? '250px' : '340px'
    return {
        imageObjectFit: 'cover',
        imageHeight,
        imageWidth: '100%',
        imageMaxHeight,
        imageMinHeight: '180px',
    } as StyledImageProps
}
