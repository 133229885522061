import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { withRouter, RouteComponentProps } from 'react-router'
import { Main } from '~/components/layout/default'
import { mediaQueries, buildClassName } from '@artnetworldwide/library-ui/utils'

const Wrapper = styled.div`
    margin: 50px auto;
    max-width: 640px;
    padding: 24px;
    text-align: center;

    h1 {
        margin-bottom: 40px;
    }

    @media ${mediaQueries.mediumUp} {
        margin: 100px auto;
    }
`

const LinkContainer = styled.div`
    margin-top: 40px;

    a {
        font-weight: 500;
        text-decoration: underline;

        :not(:first-of-type) {
            margin-left: 50px;
        }
    }
`

const FourOhFour: React.SFC<RouteComponentProps<{}>> = ({ staticContext }) => {
    if (staticContext) {
        staticContext.statusCode = 404
    }
    return (
        <>
            <Helmet>
                <title>Not Found</title>
            </Helmet>
            <Main sectionName="404">
                <Wrapper className={buildClassName('FourOhFour')}>
                    <h1>Error 404</h1>
                    <p>Sorry, this page cannot be found.</p>
                    <p>Here are a few links that might be helpful:</p>
                    <LinkContainer>
                        <a href="/">HOME</a>
                        <a href="/artists/">ARTISTS</a>
                        <a href="https://news.artnet.com/">NEWS</a>
                    </LinkContainer>
                </Wrapper>
            </Main>
        </>
    )
}

export default withRouter(FourOhFour)
