import React from 'react'
import ReactDOM from 'react-dom'
import App from '~/App'
import { ApolloProvider } from '@apollo/react-common'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createPersistedQueryLink } from 'apollo-link-persisted-queries'
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import introspectionQueryResultData from '~/fragmentTypes'
import config from '~/config'
import { BrowserRouter } from 'react-router-dom'
import { ApolloLink } from 'apollo-link'
import { isPreRenderedOnServer } from '~/environment'
import GlobalStyle from '~/GlobalStyle'

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
})

// ensure we grab apollo state off of the window object
// this avoids a double render if the object is found
const cache = new InMemoryCache({
    fragmentMatcher,
    freezeResults: true,
}).restore((window as any).__APOLLO_STATE__)

const { GRAPHQL_ENDPOINT_SUBDIR } = config
// GRAPHQL_ENDPOINT_BASE should end with a slash, so ensure the graphql subdirectory
// does NOT start with a slash before we concatenate it
const subdir = GRAPHQL_ENDPOINT_SUBDIR.startsWith('/')
    ? GRAPHQL_ENDPOINT_SUBDIR.slice(1)
    : GRAPHQL_ENDPOINT_SUBDIR
const graphqlServerUri = config.GRAPHQL_ENDPOINT_BASE + subdir

let link
if (process.env.NODE_ENV !== 'production' && config.DEBUG_QUERIES === '1') {
    // For development, it's useful to see the queries and to be able to easily copy them to graphql-playground.
    // DEBUG_QUERIES can be set in the .env.development.local or .env.local file at the root of this repo.
    link = createHttpLink({
        uri: graphqlServerUri,
        useGETForQueries: true,
    })
} else {
    link = ApolloLink.from([
        createPersistedQueryLink({ useGETForHashedQueries: true }),
        createHttpLink({ uri: graphqlServerUri }),
    ])
}

export const client = new ApolloClient({
    cache,
    link,
    ssrForceFetchDelay: isPreRenderedOnServer() ? 100 : 0,
    assumeImmutableResults: true,
    resolvers: {},
})

const defaultState = {
    currentPageUrl: '/',
}

client.cache.writeData({
    data: defaultState,
})

const renderFn = isPreRenderedOnServer() ? ReactDOM.hydrate : ReactDOM.render
renderFn(
    <ApolloProvider client={client}>
        {!isPreRenderedOnServer() && <GlobalStyle />}
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ApolloProvider>,
    document.getElementById('root')
)
