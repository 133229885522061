import React from 'react'
import styled from 'styled-components'
import invariant from 'invariant'
import { isProduction } from '~/environment'
import { defaultErrorHeading, defaultErrorMessage } from '~/messages'
import { VERTICAL_SECTION_SPACING } from '~/constants'

const Message = styled.div`
    margin-bottom: 2em;
`

const ErrorContainer = styled.div`
    margin: ${VERTICAL_SECTION_SPACING} 0;
    text-align: center;
`

interface ErrorMessageProps {
    errorObject?: Error
    heading?: string | React.ReactNode
    message?: string | React.ReactNode
    showInProduction?: boolean
}

export const ErrorMessage: React.SFC<ErrorMessageProps> = props => {
    if (isProduction() && !props.showInProduction) {
        props = getDefaultProductionProps()
    }
    const { errorObject } = props
    let { heading, message } = props
    invariant(
        errorObject || message,
        'ErrorMessage component: errorObject prop and message prop are both missing; at least one must be provided'
    )

    if (!heading) {
        heading = errorObject ? errorObject.constructor.name : 'Error'
    }
    if (!message) {
        message = errorObject!.message
    }
    return (
        <ErrorContainer>
            <h1>{heading}</h1>
            <Message>{message}</Message>
        </ErrorContainer>
    )
}

// General fallback error message for production
function getDefaultProductionProps() {
    return {
        heading: defaultErrorHeading,
        message: (
            <ErrorContainer
                dangerouslySetInnerHTML={{ __html: defaultErrorMessage }}
            />
        ),
    }
}
