import React from 'react'
import styled from 'styled-components'
import { constants, utils } from '@artnetworldwide/library-ui'

import {
    VERTICAL_SECTION_SPACING,
    SECTION_BORDER_STYLE,
    MOBILE_MAIN_CONTENT_GUTTER,
} from '~/constants'

const {
    VERTICAL_SPACE: { by30, by60 },
} = constants

const { mediaQueries } = utils

/**
 * If you want to edit the properties of these shared components
 * make sure you clone them first! Otherwise you will be editing the shared reference
 *
 * I.E. const PublicationEmptyState = EmptyState.extend`
 *        // stylelint-disable-line block-no-empty
 *      `
 *      PublicationEmptyState.displayName = 'PublicationEmptyState'
 */

export const EmptyState = styled.div`
    color: ${p => p.theme.colors.mediumGray};
    font-style: italic;
    font-weight: 100;
    min-height: 100px;
    vertical-align: middle;
`
EmptyState.displayName = 'EmptyState'

export const Section = styled.div`
    border-bottom: ${SECTION_BORDER_STYLE};
    margin: ${VERTICAL_SECTION_SPACING} 0 0 ${MOBILE_MAIN_CONTENT_GUTTER};
    padding-bottom: ${VERTICAL_SECTION_SPACING};

    @media ${mediaQueries.mediumUp} {
        margin-left: 0;
    }
`
Section.displayName = 'Section'

const UnstyledSectionTitle: React.SFC<React.HTMLAttributes<
    HTMLHeadingElement
> & {
    children: React.ReactNode
}> = ({ children, ...attrs }) => {
    if (typeof children === 'string') {
        // assume titles might contain HTML
        return <h2 {...attrs} dangerouslySetInnerHTML={{ __html: children }} />
    }
    return <h2 {...attrs}>{children}</h2>
}

export const SectionTitle = styled(UnstyledSectionTitle)`
    margin: ${by30} 0;

    @media ${mediaQueries.mediumUp} {
        margin: ${VERTICAL_SECTION_SPACING} 0;
    }
`
SectionTitle.displayName = 'SectionTitle'

export interface SpacerProp {
    topPadding?: string
    bottomPadding?: string
    border?: boolean
}
export const EndPageSpacer = styled.div<SpacerProp>`
    border-top: ${p =>
        p.border ? `1px solid ${p.theme.colors.lightGray}` : `none`};
    padding-bottom: ${p => p.bottomPadding || by60};
    padding-top: ${p => p.topPadding || by60};
`
