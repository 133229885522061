export default {
    __schema: {
        types: [
            {
                kind: 'INTERFACE',
                name: 'ViewModel',
                possibleTypes: [{ name: 'GalleryViewModel' }],
            },
            {
                kind: 'INTERFACE',
                name: 'ArtMarketParticipant',
                possibleTypes: [{ name: 'Gallery' }],
            },
            {
                kind: 'INTERFACE',
                name: 'StaticAsset',
                possibleTypes: [{ name: 'Image' }],
            },
            {
                kind: 'INTERFACE',
                name: 'EventSummary',
                possibleTypes: [
                    { name: 'ArtFairParticipationSummary' },
                    { name: 'ExhibitionSummary' },
                ],
            },
            {
                kind: 'INTERFACE',
                name: 'Creator',
                possibleTypes: [{ name: 'Artist' }, { name: 'Collaboration' }],
            },
            {
                kind: 'INTERFACE',
                name: 'Event',
                possibleTypes: [
                    { name: 'Exhibition' },
                    { name: 'ArtFairParticipation' },
                ],
            },
            {
                kind: 'INTERFACE',
                name: 'ExhibitionInterface',
                possibleTypes: [
                    { name: 'Exhibition' },
                    { name: 'ArtFairParticipation' },
                ],
            },
            {
                kind: 'UNION',
                name: 'GalleryItem',
                possibleTypes: [
                    { name: 'ExhibitionViewModel' },
                    { name: 'ArtFairParticipationViewModel' },
                    { name: 'ArtworkCollectionViewModel' },
                    { name: 'PublicationViewModel' },
                ],
            },
        ],
    },
}
