import { createGlobalStyle } from 'styled-components'
import { utils } from '@artnetworldwide/library-ui'
import { isPreRenderedOnServer } from '~/environment'

const { pixelsToRem, mediaQueries } = utils

const isSsrMode = isPreRenderedOnServer()

let fontsUri = ''
if (isSsrMode) {
    const PUBLIC_URL = process.env.PUBLIC_URL || '/'
    const withTrailingSlash = PUBLIC_URL.endsWith('/')
        ? PUBLIC_URL
        : PUBLIC_URL + '/'
    fontsUri = withTrailingSlash + 'static/fonts/'
}

// we can't conditionally use `import foo from 'foo'` statements
// so we are using require() so the we resolve the font file paths correctly
// for both SSR and default create-react-app (SPA) behavior
const artnetGroteskRegular = isSsrMode
    ? fontsUri + 'ArtnetGrotesk-Regular.woff2'
    : require('../fonts/ArtnetGrotesk-Regular.woff2')
const artnetGroteskMedium = isSsrMode
    ? fontsUri + 'ArtnetGrotesk-Medium.woff2'
    : require('../fonts/ArtnetGrotesk-Medium.woff2')
const artnetGroteskBold = isSsrMode
    ? fontsUri + 'ArtnetGrotesk-Bold.woff2'
    : require('../fonts/ArtnetGrotesk-Bold.woff2')
const artnetGroteskItalic = isSsrMode
    ? fontsUri + 'ArtnetGrotesk-RegularItalic.woff2'
    : require('../fonts/ArtnetGrotesk-RegularItalic.woff2')
const artnetGroteskMediumItalic = isSsrMode
    ? fontsUri + 'ArtnetGrotesk-MediumItalic.woff2'
    : require('../fonts/ArtnetGrotesk-MediumItalic.woff2')
const artnetGroteskBoldItalic = isSsrMode
    ? fontsUri + 'ArtnetGrotesk-BoldItalic.woff2'
    : require('../fonts/ArtnetGrotesk-BoldItalic.woff2')

/**
 * Helping web-font performance with "font-display: fallback;"
 * using the "fallback" strategy, which favors usability and
 * it is supported in most major browsers.
 * https://developers.google.com/web/updates/2016/02/font-display
 * https://font-display.glitch.me/
 */
const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: ArtnetGrotesk;
        font-display: fallback;
        font-style: normal;
        font-weight: normal;
        src: url('${artnetGroteskRegular}') format('woff2');
    }

    @font-face {
        font-family: ArtnetGrotesk;
        font-display: fallback;
        font-style: normal;
        font-weight: 500;
        src: url('${artnetGroteskMedium}') format('woff2');
    }

    @font-face {
        font-family: ArtnetGrotesk;
        font-display: fallback;
        font-style: normal;
        font-weight: bold;
        src: url('${artnetGroteskBold}') format('woff2');
    }

    @font-face {
        font-family: ArtnetGrotesk;
        font-display: fallback;
        font-style: italic;
        font-weight: normal;
        src: url('${artnetGroteskItalic}') format('woff2');
    }

    @font-face {
        font-family: ArtnetGrotesk;
        font-display: fallback;
        font-style: italic;
        font-weight: 500;
        src: url('${artnetGroteskMediumItalic}') format('woff2');
    }

    @font-face {
        font-family: ArtnetGrotesk;
        font-display: fallback;
        font-style: italic;
        font-weight: bold;
        src: url('${artnetGroteskBoldItalic}') format('woff2');
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    html {
        box-sizing: border-box;
    }

    body {
        position: relative;
        font-family: ArtnetGrotesk, Helvetica, Arial, sans-serif;
        font-size: ${pixelsToRem(13)};
        letter-spacing: 1px;
        margin: 0;
        padding: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @media ${mediaQueries.mediumUp} {
            font-size: ${pixelsToRem(16)};
        }
    }

    button {
        background-color: transparent;
    }

    img {
        border: none;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 500;
        margin: 0;
        text-transform: uppercase;
    }

    h1 {
        font-size: ${pixelsToRem(22)};
        letter-spacing: 1px;

        @media ${mediaQueries.mediumUp} {
            font-size: ${pixelsToRem(39)};
            line-height: ${pixelsToRem(40)};
        }
    }

    h2 {
        font-size: ${pixelsToRem(22)};
        letter-spacing: 1px;

        @media ${mediaQueries.mediumUp} {
            font-size: ${pixelsToRem(31)};
        }
    }

    h3 {
        font-size: ${pixelsToRem(17)};
        letter-spacing: 1px;

        @media ${mediaQueries.mediumUp} {
            font-size: ${pixelsToRem(19)};
        }
    }

    h4 {
        font-size: ${pixelsToRem(16)};
        letter-spacing: 1px;

        @media ${mediaQueries.mediumUp} {
            font-size: ${pixelsToRem(19)};
        }
    }
`

export default GlobalStyle
