import styled from 'styled-components'
import { utils } from '@artnetworldwide/library-ui'
import { NavMediaQuery } from '~/constants'
const { pixelsToRem, mediaQueries } = utils

export const headerLinksMeta = [
    { url: '/price-database/', label: 'Price Database' },
    { url: '/auctions/', label: 'Auctions' },
    { url: '/artists/', label: 'Artists' },
    { url: '/galleries/', label: 'Galleries' },
    { url: '/auction-houses/', label: 'Auction Houses' },
    { url: '/events/', label: 'Events' },
    { url: 'https://news.artnet.com', label: 'News' },
    { url: '/my-account/', label: 'My Account' },
]

export const NavLink = styled.a`
    color: #000;
    display: inline-block;
    font-size: ${pixelsToRem(12)};
    font-weight: 400;
    margin: 0 15px;
    text-decoration: none;
    vertical-align: middle;

    &:hover {
        color: #999;
    }

    @media ${NavMediaQuery} {
        margin: 0 10px;
    }
`

export const ArtnetLogoLink = styled.a`
    display: flex;
    justify-content: center;
    margin: 28px auto 22px;

    @media ${NavMediaQuery} {
        display: inline-flex;
        justify-content: left;
        margin: 28px 0 34px 54px;
    }

    @media ${mediaQueries.largeUp} {
        display: inline-flex;
        justify-content: left;
        margin: 28px 0 34px 34px;
    }
`
ArtnetLogoLink.displayName = 'ArtnetLogoLink'
