import React from 'react'

export const QueryLoaderContext = React.createContext<null | QueryLoaderOptions>(
    null
)

export interface QueryLoaderOptions {
    defaultLoadingComponent: React.ComponentType<any>
    defaultErrorComponent: React.ComponentType<{
        errorObject?: Error
    }>
}

export const QueryLoaderProvider: React.FC<{ options: QueryLoaderOptions }> = ({
    options,
    children,
}) => {
    return (
        <QueryLoaderContext.Provider value={options}>
            {children}
        </QueryLoaderContext.Provider>
    )
}
