import React from 'react'
import {
    HeaderView,
    LogoContainer,
    GlobalLinks,
    MobileBurger,
    MainNav,
} from './partials'
import { BurgerIcon, useToggle } from '@artnetworldwide/library-ui'

import { ArtnetLogo } from '~/components/ArtnetLogo'

export function Header() {
    const [state, setState] = useToggle(false)

    return (
        <HeaderView>
            <GlobalLinks>
                <a
                    href="/price-database/"
                    title="artnet Price Database"
                    aria-label="artnet Price Database"
                >
                    Price Database
                </a>
                <a
                    href="https://news.artnet.com/"
                    rel="noopener noreferrer"
                    title="artnet News"
                    aria-label="artnet News"
                    target="_blank"
                >
                    artnet News
                </a>
            </GlobalLinks>
            <LogoContainer>
                <h1 aria-label="artnet.com">
                    <a href="/" title="artnet.com">
                        <ArtnetLogo aria-label="artnet logo" />
                    </a>
                </h1>

                <MobileBurger onClick={setState} aria-label="open navigation">
                    <BurgerIcon
                        aria-label="burger icon"
                        height="16px"
                        width="24px"
                    />
                </MobileBurger>
            </LogoContainer>

            <MainNav toggle={state}>
                {/* Exception: we are using global link for level page nav. 
            Usuallly this would be page-navigation links as in Intelligence Report */}
                <a
                    href="/price-database/"
                    title="artnet Price Database"
                    aria-label="artnet Price Database"
                >
                    Price Database
                </a>
                <a
                    href="https://news.artnet.com/"
                    rel="noopener noreferrer"
                    title="artnet News"
                    aria-label="artnet News"
                    target="_blank"
                >
                    artnet News
                </a>
            </MainNav>
        </HeaderView>
    )
}
