import React from 'react'
import styled from 'styled-components'
import { SmallArrowIcon } from '@artnetworldwide/library-ui'
import { colors } from '../theme'

const BackView = styled.div`
    cursor: pointer;
    margin: 50px auto 0;
    text-align: center;
    width: 94px;

    * {
        display: block;
        margin: 0 auto;
    }

    b {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
`

const Circle = styled.div`
    border: 1px solid ${p => p.theme.colors.mediumGray};
    border-radius: 50%;
    height: 25px;
    margin-bottom: 5px;
    width: 25px;

    svg {
        fill: ${p => p.theme.colors.mediumGray};
        margin-bottom: 0;
        margin-top: 6px;
        transform: rotate(90deg);
    }
`

export const BackToTop = () => {
    return (
        <BackView
            aria-label="Back to the top of page"
            role="button"
            onClick={() => {
                window.scrollTo({ behavior: 'smooth', left: 0, top: 0 })
            }}
        >
            <Circle>
                <SmallArrowIcon
                    stroke={colors.mediumGray}
                    height="13px"
                    width="13px"
                    aria-label="arrow icon"
                />
            </Circle>
            <b>Back To Top</b>
        </BackView>
    )
}
