import React from 'react'
import styled from 'styled-components'
import {
    FacebookIcon,
    InstagramIcon,
    PinterestIcon,
    WeiboIcon,
    TwitterIcon,
    utils,
} from '@artnetworldwide/library-ui'

const { pixelsToRem } = utils
const StyledFooter = styled.nav`
    background: ${p => p.theme.colors.dark};
    font-size: ${pixelsToRem(12)};
    padding: 40px 20px;
    text-align: center;

    > div {
        display: block;
        margin-bottom: 10px;
    }
`

const FooterAnchor = styled.a`
    color: ${p => p.theme.colors.white};
    display: inline-block;
    margin: 3px;
    padding: 4px;
    text-decoration: none;

    &.selected {
        color: ${p => p.theme.colors.brand};

        &:hover {
            color: ${p => p.theme.colors.brand};
        }
    }

    &:hover {
        color: ${p => p.theme.colors.mediumGray};
    }
`

const SocialAnchor = styled(FooterAnchor)`
    margin: 12px 2px;
`

export class Footer extends React.PureComponent<{}> {
    footerData = {
        products: [
            {
                url: '/price-database/',
                label: 'Price Database',
            },
            {
                url: '/market-alerts/',
                label: 'Market Alerts',
            },
            {
                url: '/analytics-reports/',
                label: 'Analytics Reports',
            },
            {
                url: '/gallery-network/',
                label: 'Gallery Network',
            },
            {
                url: '/auction-house-partnerships/',
                label: 'Auction House Partnerships',
            },
        ],
        info: [
            {
                url: '/about/aboutindex.asp?F=1',
                label: 'About',
            },
            {
                url: '/aboutus/contact_us.aspx',
                label: 'Contact',
            },
            { url: '/jobs/', label: 'Jobs' },
            { url: '/help/faq', label: 'FAQ' },
            {
                url: '/sitemap/siteindex.asp?F=1',
                label: 'Site Map',
            },
            { url: '/advertising/', label: 'Advertise' },
            {
                url: '/about/terms-and-conditions-of-use',
                label: 'Terms',
            },
            {
                url: '/about/privacy-formal.asp',
                label: 'Privacy',
            },
            {
                url: '/about/privacy-formal.asp#Cookies',
                label: 'Cookies',
            },
        ],
        social: [
            { url: 'http://www.facebook.com/artnet/', label: 'facebook' },
            { url: 'http://twitter.com/artnet/', label: 'twitter' },
            { url: 'http://pinterest.com/artnet/', label: 'pinterest' },
            { url: 'http://instagram.com/artnet/', label: 'instagram' },
            { url: 'http://www.weibo.com/artnet/', label: 'weibo' },
        ],
        languageOptions: [
            { url: 'https://www.artnet.com', label: 'English (US)' },
            { url: 'https://www.artnet.de', label: 'Deutsch' },
            { url: 'https://www.artnet.fr', label: 'Français' },
        ],
    }

    renderIcon = (iconLabel: string) => {
        const dimensions = { width: '32px', height: '32px' }
        if (iconLabel === 'facebook') {
            return <FacebookIcon {...dimensions} />
        }
        if (iconLabel === 'twitter') {
            return <TwitterIcon {...dimensions} />
        }
        if (iconLabel === 'pinterest') {
            return <PinterestIcon {...dimensions} />
        }
        if (iconLabel === 'instagram') {
            return <InstagramIcon {...dimensions} />
        }
        if (iconLabel === 'weibo') {
            return <WeiboIcon {...dimensions} />
        }

        return null
    }

    renderProducts() {
        const { products } = this.footerData
        return products.map((anchor, index) => (
            <FooterAnchor key={index} href={anchor.url}>
                {anchor.label}
            </FooterAnchor>
        ))
    }

    renderInfo() {
        const { info } = this.footerData
        return info.map((anchor, index) => (
            <FooterAnchor key={index} href={anchor.url}>
                {anchor.label}
            </FooterAnchor>
        ))
    }

    renderSocial() {
        const { social } = this.footerData
        return social.map((anchor, index) => (
            <SocialAnchor
                aria-label={anchor.label}
                target="_blank"
                rel="noopener"
                key={index}
                href={anchor.url}
            >
                {this.renderIcon(anchor.label)}
            </SocialAnchor>
        ))
    }

    render() {
        return (
            <footer>
                <StyledFooter>
                    <div>{this.renderProducts()}</div>
                    <div>{this.renderInfo()}</div>
                    <div>{this.renderSocial()}</div>
                    <div style={{ color: '#bcbec0' }}>
                        ©{new Date().getFullYear()} Artnet Worldwide
                        Corporation. All rights reserved.
                    </div>
                </StyledFooter>
            </footer>
        )
    }
}
