export * from './constants/images'

export const colors = {
    brand: '#fc4700',
    dark: '#000000',
    gray: '#5e5e5e',
    mediumGray: '#9B9B9B',
    lightGray: '#D8D8D8',
    lightestGray: '#f0f0f0',
    white: '#ffffff',
}
