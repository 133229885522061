// import chunk from 'lodash.chunk'
import { DataLayer } from '~/graphql/types/GoogleTagManager'
import { css } from 'styled-components'
import { Cache } from '~/lib/cache/types'
import { MANIFEST_CACHE_KEY } from '../../ssr/syndicated-components-api'

// Not currently used, but we might use it in the future
/**
 * takes items to display in a column based grid and transforms the
 * order so that they are displayed correctly
 *
 * @NB remember to flatten the result!
 *
 * Without sorting the items this way you could get a grid like:
 *
 *  1 4 7
 *  2 5 8
 *  3 6 9
 *
 * This matrix returned, for ex., could look like [[1, 4, 7], [2, 5, 8], [3, 6, 9]]
 * Once flattened, the array items will be in the correct order for our grid to work:
 *
 *  1 2 3
 *  4 5 6
 *  7 8 9
 */
/*
export const createOrderedColumnGrid = <T>(
    items: T[],
    numberOfColumns: number
): T[][] => {
    const matrix = chunk<T>(items, numberOfColumns)
    return matrix.reduce(
        (acc, array) => {
            array.forEach((item, innerIndex) => {
                if (Array.isArray(acc[innerIndex])) {
                    acc[innerIndex].push(item)
                } else {
                    acc[innerIndex] = [item]
                }
            })
            return acc
        },
        [] as T[][]
    )
}
*/

/**
 * @NB automatically adds a trailing slash to pathToAppend, so make sure to leave it out
 */
export const trailingSlashIt = (url: string, pathToAppend?: string) => {
    let urlWithSlash = url.endsWith('/') ? url : `${url}/`
    if (pathToAppend) {
        urlWithSlash = `${urlWithSlash}${pathToAppend}/`
    }
    return urlWithSlash
}

/**
 * adds key value pairs to window.dataLayer
 * GTM uses window.dataLayer to send additional information to tags
 */
export const addDataLayerProps = (dataObject: DataLayer) => {
    if (typeof window === 'undefined') {
        return
    }
    const windowRef = window as any
    windowRef.dataLayer = windowRef.dataLayer || []
    return windowRef.dataLayer.push(dataObject)
}

/**
 * generate a gradient border in CSS, typically used with an :after or :before pseudo-element
 */
export const generateGradient = ({
    bottom = 'initial',
    direction = 'right',
    fromColor = 'rgba(255, 255, 255, 0.1)',
    height = 'initial',
    left = 'initial',
    right = 'initial',
    top = 'initial',
    toColor = 'white',
    width = '40px',
}) => {
    return css`
        position: absolute;
        top: ${top};
        right: ${right};
        bottom: ${bottom};
        left: ${left};

        background: linear-gradient(to ${direction}, ${fromColor}, ${toColor});
        content: '';
        height: ${height};
        width: ${width};
    `
}

export function catchError<F extends (...args: any[]) => any>(fn: F): F {
    return ((...args: any[]) => {
        return fn(...args).catch((err: any) => {
            throw new Error(err)
        })
    }) as F
}

// This is a general fetching function that can be used with any API endpoint that returns JSON.
// Added for fetching manifest for unified header.
export async function fetchJson(
    url: string,
    options: RequestInit = {},
    cache?: Cache
) {
    const defaultHeaders = { Accept: 'application/json' }
    if (options.body) defaultHeaders['Content-type'] = 'application/json'

    const body =
        typeof options.body === 'object'
            ? JSON.stringify(options.body)
            : options.body

    const fetchOptions = {
        ...options,
        body,
        headers: {
            ...defaultHeaders,
            ...options.headers,
        },
    }

    const response = await fetch(url, fetchOptions)
    let json
    try {
        json = await response.json()
    } catch (e) {
        throw Error(`Error parsing JSON from server for URL ${url}`)
    }
    const cacheControlHeader = response.headers.get('cache-control')

    if (cacheControlHeader) {
        const maxAge = cacheControlHeader.match(/\bmax-age=(\d+)/)

        if (cache && maxAge) {
            await cache.set(MANIFEST_CACHE_KEY, json, maxAge[1])
        }
    }

    if (response.status !== 200) {
        const serverMessage = await response.text()
        throw Error(
            `Error fetching JSON (HTTP Response Code ${response.status}): ${serverMessage}`
        )
    }

    return json
}

export const fetchIt = catchError(async (url: string) => {
    const res = await fetch(url)
    const data = await res.json()
    return data
})

// from https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript/5158301#5158301
export const getParameterByName = (queryString: string, name: string) => {
    var match = RegExp('[?&]' + name + '=([^&]*)').exec(queryString)
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}
