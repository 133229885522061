import { utils, SearchIcon, BurgerIcon } from '@artnetworldwide/library-ui'
import React from 'react'
import styled from 'styled-components'
import { TypeAhead } from '~/components'
import {
    DESKTOP_MAIN_CONTENT_GUTTER,
    CONTENT_MAX_WIDTH,
    NavMediaQuery,
    ProTabletPortrait,
    TabletLandscape,
} from '~/constants'
import { DesktopSearchForm, TabletSearchForm } from './forms'
import { ArtnetLogoLink, NavLink, headerLinksMeta } from './links'
import {
    MobileMenuButton,
    MobileNavList,
    MobileSearchButton,
    MobileSearchWrapper,
} from './mobileNav'

import { ArtnetLogo } from '~/components/ArtnetLogo'

const { mediaQueries, buildClassName } = utils

const HeaderWrapper = styled.header`
    margin: auto;
    max-width: ${CONTENT_MAX_WIDTH};
    padding: 20px 0 0 0;

    nav {
        position: relative;
        border-top: 1px solid ${p => p.theme.colors.lightGray};
        margin: 0 20px;
        text-align: center;
    }

    @media ${mediaQueries.mediumUp} {
        padding: 0 ${DESKTOP_MAIN_CONTENT_GUTTER};
    }

    @media ${NavMediaQuery} {
        padding: 0 0;

        nav {
            border-bottom: 1px solid ${p => p.theme.colors.lightGray};
            border-top: 1px solid ${p => p.theme.colors.lightGray};
            padding: 20px 0;
            text-align: center;
        }
    }

    @media ${mediaQueries.largeUp} {
        nav {
            border-bottom: 1px solid ${p => p.theme.colors.lightGray};
            border-top: 1px solid ${p => p.theme.colors.lightGray};
            margin: 0;
            padding: 34px 0;
            text-align: left;
        }
    }
`
HeaderWrapper.displayName = 'HeaderWrapper'

const NavItemsWrapper = styled.div`
    display: none;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        display: inline-block;
    }

    @media ${NavMediaQuery} {
        display: inline-block;

        li:first-of-type {
            a {
                margin-left: 0;
            }
        }

        li:last-of-type {
            margin-left: 39px;

            a {
                margin-right: 0;
            }
        }
    }

    @media ${TabletLandscape} {
        display: inline-block;

        li:first-of-type {
            a {
                margin-left: 0;
            }
        }

        li:last-of-type {
            position: absolute;
            right: 0;

            a {
                margin-right: 0;
            }
        }
    }

    @media ${ProTabletPortrait} {
        display: inline-block;

        li:first-of-type {
            a {
                margin-left: 0;
            }
        }

        li:last-of-type {
            position: absolute;
            right: 0;

            a {
                margin-right: 0;
            }
        }
    }

    @media ${mediaQueries.largeUp} {
        display: inline-block;

        li:first-of-type {
            a {
                margin-left: 15px;
            }
        }

        li:last-of-type {
            position: absolute;
            right: 0;

            a {
                margin-right: 0;
            }
        }
    }
`
NavItemsWrapper.displayName = 'NavItemsWrapper'

enum MenuOptions {
    MOBILE_NAV,
    MOBILE_SEARCH,
}

class Header extends React.PureComponent {
    private initialMobileNavHeight = 6

    private mobileNavContainer: HTMLElement | null
    private mobileSearchContainer: HTMLElement | null

    state = {
        currentToggle: MenuOptions.MOBILE_NAV,
        mobileNavHeight: null,
        mobileSearchHeight: null,
        initialized: false,
        isOpen: true,
    }

    componentDidMount() {
        let updatedNavHeight = null
        if (this.mobileNavContainer != null) {
            updatedNavHeight = this.getDropdownNavHeight(
                this.mobileNavContainer.children
            )
        }

        let updatedSearchHeight = null
        if (this.mobileSearchContainer != null) {
            updatedSearchHeight = this.mobileSearchContainer.clientHeight
        }

        this.setState({
            initialized: true,
            mobileNavHeight: updatedNavHeight,
            mobileSearchHeight: updatedSearchHeight,
            isOpen: false,
        })
    }

    private setMenuState(menuName: MenuOptions) {
        if (!this.state.initialized) {
            return
        }

        // if clicked menu is already selected, simply open or close it
        if (this.state.currentToggle === menuName) {
            this.setState({
                isOpen: !this.state.isOpen,
            })
            return
        }

        // if neither menu is open, set the correct menu and open it
        if (!this.state.isOpen) {
            this.setState({
                currentToggle: menuName,
                isOpen: true,
            })
            return
        }

        // if menu is already open, leave it open and switch to the correct menu
        this.setState({
            currentToggle: menuName,
        })
    }

    private getDropdownNavHeight(children: HTMLCollection) {
        const navItemElements = Array.from(children)
        return navItemElements.reduce(
            (totalHeight, el) =>
                (totalHeight =
                    totalHeight +
                    (el.clientHeight +
                        2)) /* account for 1px border on top and bottom of el */,
            this.initialMobileNavHeight
        )
    }

    private toggleMobileNav = () => {
        // recalculate height if necessary
        if (
            this.state.mobileNavHeight === this.initialMobileNavHeight &&
            this.mobileNavContainer
        ) {
            this.setState({
                mobileNavHeight: this.getDropdownNavHeight(
                    this.mobileNavContainer.children
                ),
            })
        }

        this.setMenuState(MenuOptions.MOBILE_NAV)
    }

    private toggleMobileSearch = () => {
        // recalculate height if necessary
        if (this.state.mobileSearchHeight === 0 && this.mobileSearchContainer) {
            this.setState({
                mobileSearchHeight: this.mobileSearchContainer.clientHeight,
            })
        }

        this.setMenuState(MenuOptions.MOBILE_SEARCH)
    }

    render() {
        const searchLink = '/search/?'
        const typeHeadProps = {
            resultCount: 7,
        }
        return (
            <HeaderWrapper className={buildClassName('HeaderWrapper')}>
                <ArtnetLogoLink
                    aria-label="artnet"
                    className={buildClassName('ArtnetLogoLink')}
                    href="/"
                >
                    <ArtnetLogo aria-label="artnet logo" />
                </ArtnetLogoLink>
                <nav>
                    <MobileMenuButton
                        aria-label="Navigation"
                        className={buildClassName('MobileMenuButton')}
                        onClick={this.toggleMobileNav}
                    >
                        <BurgerIcon width="24px" height="16px" />
                    </MobileMenuButton>

                    <DesktopSearchForm action={searchLink}>
                        <TypeAhead {...typeHeadProps} />
                    </DesktopSearchForm>
                    <NavItemsWrapper
                        className={buildClassName('NavItemsWrapper')}
                    >
                        <ul>
                            {headerLinksMeta.map(link => (
                                <li key={link.url}>
                                    <NavLink href={link.url}>
                                        {link.label}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </NavItemsWrapper>
                    <TabletSearchForm action={searchLink}>
                        <TypeAhead {...typeHeadProps} />
                    </TabletSearchForm>

                    <MobileNavList
                        className={buildClassName('MobileNavList')}
                        contentHeight={this.state.mobileNavHeight}
                        ref={ref => (this.mobileNavContainer = ref)}
                        isOpen={
                            this.state.isOpen &&
                            this.state.currentToggle === MenuOptions.MOBILE_NAV
                        }
                    >
                        {headerLinksMeta.map(link => (
                            <li key={link.url}>
                                <NavLink href={link.url}>{link.label}</NavLink>
                            </li>
                        ))}
                    </MobileNavList>
                    <MobileSearchWrapper
                        className={buildClassName('MobileSearchWrapper')}
                        contentHeight={this.state.mobileSearchHeight}
                        isOpen={
                            this.state.isOpen &&
                            this.state.currentToggle ===
                                MenuOptions.MOBILE_SEARCH
                        }
                    >
                        <div ref={ref => (this.mobileSearchContainer = ref)}>
                            <form action={searchLink}>
                                <TypeAhead
                                    {...typeHeadProps}
                                    topPlacement="45px"
                                />
                            </form>
                        </div>
                    </MobileSearchWrapper>
                    <MobileSearchButton
                        aria-label="search"
                        className={buildClassName('MobileSearchButton')}
                        onClick={this.toggleMobileSearch}
                    >
                        <SearchIcon width="18px" height="18px" />
                    </MobileSearchButton>
                </nav>
            </HeaderWrapper>
        )
    }
}

export { Header }
