import React, { useState } from 'react'
import styled from 'styled-components'
import {
    Modal,
    StyledLink,
    utils,
    constants,
} from '@artnetworldwide/library-ui'
import { buildClassName } from '@artnetworldwide/library-ui/utils'

const { mediaQueries } = utils
const {
    VERTICAL_SPACE: { by20, by30 },
} = constants

// @TODO: spacing can be passed in
const ReadMore = styled(StyledLink)`
    display: inline-block;
    margin-top: ${by20};

    @media ${mediaQueries.mediumUp} {
        margin-top: ${by30};
    }
`

const Header = styled.h3`
    margin-bottom: ${by20};

    @media ${mediaQueries.mediumUp} {
        margin-bottom: ${by30};
    }
`

const HTMLContent = styled.div`
    line-height: 30px;
`

export interface Props {
    title?: string
    content?: string
    cta?: string
    open: boolean
}

export const ManagedModal: React.FC<Props> = props => {
    const [open, setOpen] = useState(props.open)

    function toggleOpen(e: React.MouseEvent<HTMLElement>) {
        e.preventDefault()
        setOpen(!open)
    }

    const { content, title, children, cta = 'Read More' } = props
    const triggerClass =
        (title && title.split(' ').join('_')) || 'OverlayTrigger'
    return (
        <>
            <ReadMore
                data-testid="read-more"
                linkStyle="smallUpper"
                className={buildClassName(`${triggerClass}_ReadMore`)}
                href="#read-more"
                onClick={toggleOpen}
            >
                {cta}
            </ReadMore>
            <Modal
                show={open}
                onRequestClose={toggleOpen}
                width={'900px'}
                padding={'60px 113px'}
                minHeight={'700px'}
            >
                {title && <Header>{title}</Header>}
                {content && (
                    <HTMLContent
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                )}
                <HTMLContent>{children}</HTMLContent>
            </Modal>
        </>
    )
}

export default ManagedModal
