import React from 'react'
import styled from 'styled-components'
import { utils } from '@artnetworldwide/library-ui'

export const PageHeader = styled.h2`
    /* border-bottom: 1px solid ${p => p.theme.colors.lightGray}; */
    font-family: ArtnetGrotesk, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin: 0 16px;
    padding: 0 0 20px 0;
    text-align: center;
    text-transform: capitalize;

    @media ${utils.mediaQueries.mediumUp} {
        font-size: 16px;
        font-weight: normal;
        margin: 0;
        padding: 30px 0 0 0;
    }
`

export const SectionHeader = styled.h3`
    font-size: 20px;
    font-weight: 400;
    margin-top: 0;
    text-align: center;
    text-transform: capitalize;
`
export const Copy = styled.p`
    margin: 24px 16px 0;

    @media ${utils.mediaQueries.mediumUp} {
        margin: 36px 16px 0;
    }
`

export const Block = styled.div`
    border-bottom: 1px solid ${p => p.theme.colors.lightGray};
    margin: 24px 0;
    padding-bottom: 40px;

    @media ${utils.mediaQueries.mediumUp} {
        margin: 40px 0;
        padding-bottom: 40px;
    }
`

/**
 * Step Instructions
 */
export interface StepProps {
    indicator?: string
}

export const Step = styled.div<StepProps>`
    position: relative;
    margin: 24px 0;
    min-height: 24px;
    padding: 0 16px 0 52px;

    &:after {
        position: absolute;
        top: 0;
        left: 16px;
        background: ${p => p.theme.colors.dark};
        border-radius: 50%;
        color: ${p => p.theme.colors.white};
        content: ${p => `"${p.indicator}"`};
        display: block;
        font-size: 10px;
        font-weight: bold;

        height: 24px;
        line-height: 24px;
        text-align: center;
        width: 24px;
    }

    @media ${utils.mediaQueries.mediumUp} {
        margin: 40px 0;
        min-height: 48px;
        padding-left: 88px;

        &:after {
            top: 0;
            left: 16px;
            background: ${p => p.theme.colors.dark};
            border-radius: 50%;
            color: ${p => p.theme.colors.white};
            content: ${p => `"${p.indicator}"`};
            display: block;
            font-size: 16px;
            font-weight: bold;

            height: 48px;
            line-height: 48px;
            text-align: center;
            width: 48px;
        }
    }
`

/**
 * Picture Component
 */
export interface PictureProps {
    altText: string
    largeImageUrl: string
    mediumImageUrl: string
}

const PictureView = styled.picture`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;

    img {
        width: 100%;
    }
`

export const Picture = ({
    altText,
    largeImageUrl,
    mediumImageUrl,
}: PictureProps) => {
    return (
        <PictureView>
            <source
                media={utils.mediaQueries.mediumUp}
                srcSet={largeImageUrl}
            />
            <img src={mediumImageUrl} alt={altText} />
        </PictureView>
    )
}

/**
 * Contact Detail
 */

export const ContactDetail = styled.div`
    line-height: 18px;
    text-align: center;

    h3 {
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 1px;
        margin-bottom: 50px;
        text-transform: capitalize;
    }

    h4 {
        font-size: 12px;
        font-weight: bold;
        margin-bottom: 16px;
    }

    em {
        font-style: normal;
        font-weight: bold;
    }

    p {
        margin: 0;
    }

    @media ${utils.mediaQueries.mediumUp} {
        font-size: 12px;
    }
`
