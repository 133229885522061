import React from 'react'
import gql from 'graphql-tag'
import { Query } from '@apollo/react-components'
import { ErrorMessage } from '~/components/ErrorMessage'
import { LoadingIcon } from '@artnetworldwide/library-ui'
import { Loader } from './partials'
import { SearchHint as Hint } from '~/graphql/types'
import { buildClassName } from '@artnetworldwide/library-ui/utils'

export const GET_SEARCH_HINT = gql`
    query($term: String!, $pageSize: Int) {
        search(term: $term, pageSize: $pageSize) {
            results {
                id
                name
                url
            }
        }
    }
`
export interface Props {
    term: string
    pageSize?: number
}

export const SearchHint: React.SFC<Props> = ({ term, pageSize = 7 }) => {
    return (
        <Query<any, Props>
            query={GET_SEARCH_HINT}
            variables={{ term, pageSize }}
        >
            {({ loading, error, data }) => {
                if (loading) {
                    return (
                        <Loader>
                            <LoadingIcon width="120px" height="120px" />
                        </Loader>
                    )
                }

                if (error) {
                    return (
                        <ErrorMessage
                            heading="Apollo Error"
                            errorObject={error}
                        />
                    )
                }
                const {
                    search: { results },
                } = data

                if (results && results.length <= 0) {
                    return null
                }

                return results.map((item: Hint, index: number) => {
                    return (
                        <a
                            key={index}
                            href={item.url}
                            className={buildClassName('SearchHintLink')}
                        >
                            {item.name}
                        </a>
                    )
                })
            }}
        </Query>
    )
}
