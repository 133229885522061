import React from 'react'
import styled from 'styled-components'
import {
    FacebookIcon,
    TwitterIcon,
    PinterestIcon,
    InstagramIcon,
    WeiboIcon,
} from '@artnetworldwide/library-ui'

export const FooterView = styled.footer`
    align-items: center;
    background: ${p => p.theme.colors.dark};
    color: ${p => p.theme.colors.mediumGray};
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: center;
    padding: 40px 0;
    text-align: center;
    width: 100%;

    & a {
        display: inline-block;
        margin: 0 5px;
    }
`

export const Footer: React.FC = () => {
    const currentYear = new Date().getFullYear()
    const dimensions = { width: '32px', height: '32px' }
    return (
        <FooterView>
            <p>
                <a
                    href="https://www.facebook.com/artnet"
                    rel="noopener noreferrer"
                    title="Artnet@Facebook"
                    target="_blank"
                >
                    <FacebookIcon {...dimensions} />
                </a>
                <a
                    href="http://twitter.com/artnet"
                    rel="noopener noreferrer"
                    title="Artnet@Twitter"
                    target="_blank"
                >
                    <TwitterIcon {...dimensions} />
                </a>
                <a
                    href="http://pinterest.com/artnet/"
                    rel="noopener noreferrer"
                    title="Artnet@Pinterest"
                    target="_blank"
                >
                    <PinterestIcon {...dimensions} />
                </a>
                <a
                    href="http://instagram.com/artnet"
                    rel="noopener noreferrer"
                    title="Artnet@Instagram"
                    target="_blank"
                >
                    <InstagramIcon {...dimensions} />
                </a>
                <a
                    href="http://www.weibo.com/artnet"
                    rel="noopener noreferrer"
                    title="Artnet@Weibo"
                    target="_blank"
                >
                    <WeiboIcon {...dimensions} />
                </a>
            </p>
            <p>
                ©{currentYear} Artnet Worldwide Corporation. All Rights
                Reserved.
            </p>
        </FooterView>
    )
}
