import styled from 'styled-components'
import { utils } from '@artnetworldwide/library-ui'

const { mediaQueries } = utils

const SearchForm = styled.form`
    position: relative;
    display: none;
    font-weight: 100;

    svg {
        position: absolute;
        top: 50%;
        right: 8px;
        height: 18px;
        transform: translateY(-50%);
        width: 18px;
        z-index: -1;
    }
`

export const DesktopSearchForm = styled(SearchForm)`
    margin-right: 16px;
    width: 315px;

    @media ${mediaQueries.largeUp} {
        display: inline-block;
    }
`

export const TabletSearchForm = styled(SearchForm)`
    margin: 20px auto 0;
    text-align: center;
    width: 40%;

    @media ${mediaQueries.mediumOnly} {
        display: block;
    }
`
