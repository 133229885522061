// Production defaults
const config = {
    // Should end with a slash. According to our current conventions, this should always be '/' except if running
    // locally and you want to point to a deployed GraphQL server.
    GRAPHQL_ENDPOINT_BASE: '/',
    // This should not start with a slash (since it is concatenated after GRAPHQL_ENDPOINT_BASE)
    GRAPHQL_ENDPOINT_SUBDIR: 'graphql',
    // Turn on DEBUG_QUERIES to disable Apollo's persisted queries feature. This makes it possible to see the full query
    // in the network tab in dev tools. DEBUG_QUERIES will be ignored if NODE_ENV=production
    DEBUG_QUERIES: '0',

    // These deliberately do not have default values, but they are required for analytics tracking to function:
    GTM_CONTAINER_ID: '',
    GTM_AUTH: '',
    GTM_PREVIEW: '',
    SYNDICATED_COMPONENTS_MANIFEST_ENDPOINT: '',
}

if (typeof window === 'object') {
    const windowObj = window as any
    // If there's no window.CONFIG object, that means we're running the SPA app in development mode
    // via create-react-app's `start` command (yarn start).
    // So we need to populate that window.CONFIG object that would ordinarily be set by the SSR server.
    if (process.env.NODE_ENV !== 'production' && !windowObj.CONFIG) {
        windowObj.CONFIG = {}
        for (const varName of Object.keys(config)) {
            windowObj.CONFIG[varName] = process.env['REACT_APP_' + varName]
        }
    }

    // Get settings from window.CONFIG variables, if set
    Object.assign(config, windowObj.CONFIG)
}

export default config
