import React, { PureComponent } from 'react'
import Helmet from 'react-helmet'
import { addDataLayerProps } from '~/utils/helpers'

interface MetaDataOpenGraphProps {
    pageTitle: string
    description: string
    keywords: string
    canonicalUrl: string
    ogTitle: string
    ogType?: string
    ogUrl: string
    ogImage?: string
    routeChanged: boolean
}

export class MetaDataOpenGraph extends PureComponent<MetaDataOpenGraphProps> {
    /**
     * fire synthetic page view only once meta tags have updated
     */
    private triggerSyntheticPageView = () => {
        if (this.props.routeChanged) {
            addDataLayerProps({
                event: 'syntheticPageView',
            })
        }
    }

    render() {
        const {
            children,
            ogType = 'website',
            canonicalUrl,
            description,
            keywords,
            ogTitle,
            ogUrl,
            ogImage,
            pageTitle,
        } = this.props
        return (
            <Helmet onChangeClientState={this.triggerSyntheticPageView}>
                <title>{pageTitle}</title>
                <link rel="canonical" href={canonicalUrl} />
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />
                <meta property="og:title" content={ogTitle} />
                <meta property="og:type" content={ogType} />
                <meta property="og:url" content={ogUrl} />
                {ogImage && <meta property="og:image" content={ogImage} />}
                {children}
            </Helmet>
        )
    }
}
