import React from 'react'
import { ManagedModal } from '~/components'
import styled from 'styled-components'
import { utils } from '@artnetworldwide/library-ui'
// @FIXME Blurb is a global component but it's depending on the gallery-specific SectionTitle.
// SectionTitle should probably be a global component too.
import { SectionTitle } from '~/pages/galleries/partials/sharedComponents'

const { ellipsify } = utils

const ContentWrapper = styled.div`
    margin: 30px 0;
`

const AboutSectionHTML = styled.div`
    line-height: 30px;
`

interface Props {
    title?: string
    copy: string
    cta?: string
    maxChar: number
}

export const Blurb: React.SFC<Props> = ({
    title,
    copy,
    cta = 'Read More',
    maxChar = 320,
    children,
}) => {
    // @NB explicitly using React.Fragment to circumvent these issues:
    // https://github.com/airbnb/enzyme/issues/1178 and https://github.com/airbnb/enzyme/issues/1149
    // @TODO switch back to <>{content}</> syntax once the problems are resolved
    return (
        <React.Fragment>
            {title && <SectionTitle>{title}</SectionTitle>}
            {copy && (
                <AboutSectionHTML
                    dangerouslySetInnerHTML={{
                        __html: ellipsify(copy, maxChar),
                    }}
                />
            )}
            {copy.length > maxChar ? (
                <ManagedModal
                    open={false}
                    title={title}
                    content={copy}
                    cta={cta}
                >
                    {children}
                </ManagedModal>
            ) : (
                children && <ContentWrapper>{children}</ContentWrapper>
            )}
        </React.Fragment>
    )
}

Blurb.displayName = 'Blurb'
