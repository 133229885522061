import React from 'react'
import { SearchIcon, CloseIcon, utils } from '@artnetworldwide/library-ui'
import { HintView, StyledInput, CloseButton } from './partials'
import { SearchHint } from './SearchHint'
import { buildClassName } from '@artnetworldwide/library-ui/utils'

const { debounce } = utils

// Key Codes
const ESCAPE = 27
const ENTER = 13

interface Props {
    resultCount?: number
    topPlacement?: string
}

interface State {
    input: string
    term: string
    displayHint: boolean
}

export class TypeAhead extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            input: '',
            term: '',
            displayHint: false,
        }

        this.node = React.createRef()
    }

    private node: React.RefObject<HTMLDivElement>

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickAway)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickAway)
    }

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        const val = e.target.value

        this.setState({
            input: val,
            displayHint: true,
        })

        const updateTerm = debounce(() => {
            this.setState({
                term: this.state.input,
            })
        }, 300)

        updateTerm()
    }

    handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === ESCAPE) {
            return this.hideHint()
        }
        if (e.keyCode === ENTER) {
            const form = e.currentTarget.parentNode!
                .parentNode as HTMLFormElement
            form.submit()
        }
    }

    handleCloseClick = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({
            input: '',
            displayHint: false,
        })
    }

    handleClickAway = (e: Event) => {
        if (!this.node.current!.contains(e.target as HTMLElement)) {
            if (!this.state.displayHint) {
                return
            }
            this.hideHint()
        }
    }

    hideHint() {
        this.setState({ displayHint: false })
    }

    showHint = () => {
        if (this.state.input !== '' && !this.state.displayHint) {
            this.setState({ displayHint: true })
        }
    }

    renderHint() {
        const { input, displayHint, term } = this.state
        const { topPlacement = '30px', resultCount } = this.props

        if (!displayHint) {
            return null
        }

        return (
            <HintView top={topPlacement}>
                <CloseButton onClick={this.handleCloseClick}>
                    <CloseIcon stroke="#D8D8D8" width="20px" height="20px" />
                </CloseButton>
                <a
                    className={`${buildClassName('SearchTerm')} term`}
                    href={`/search/?q=${input}`}
                >
                    {`Search for "${input}"`}
                </a>
                {term !== '' && term === input && (
                    <SearchHint term={term} pageSize={resultCount} />
                )}
            </HintView>
        )
    }

    render() {
        return (
            <div ref={this.node}>
                <StyledInput
                    aria-label="search"
                    className={buildClassName('SearchInput')}
                    type="text"
                    name="q"
                    onChange={this.handleInputChange}
                    placeholder="Search"
                    autoComplete="off"
                    value={this.state.input}
                    onKeyDown={this.handleKeyDown}
                    onClick={this.showHint}
                />
                <SearchIcon width="18px" height="18px" />
                {this.state.input !== '' && this.renderHint()}
            </div>
        )
    }
}
