import React from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { Content, Main } from './Main'

export { Header, Footer, Main }

export default function DefaultLayout({
    unifiedHeaderHtml,
    children,
}: {
    unifiedHeaderHtml: string
    children: React.ReactNode
}) {
    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: unifiedHeaderHtml }} />
            <Content>{children}</Content>
            <Footer />
        </>
    )
}
