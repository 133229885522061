import { OperationVariables } from '@apollo/react-common'

export { QueryLoader } from './QueryLoader'
import { QueryLoaderProps, QueryLoaderResult } from './QueryLoader'

// use `export type` instead of just `export` to prevent webpack build errors
// see https://github.com/babel/babel-loader/issues/603
export type QueryLoaderProps<
    TData = any,
    TVariables = OperationVariables
> = QueryLoaderProps<TData, TVariables>
export type QueryLoaderResult<
    TData,
    TVariables = OperationVariables
> = QueryLoaderResult<TData, TVariables>
