/**
 * Router
 */
export const PAST_EXHIBITIONS_SLUG = 'past-exhibitions'

/**
 * Labels
 */
export const CURRENT_EXHIBITIONS_LABEL = 'Currently on View'
export const UPCOMING_EXHIBITIONS_LABEL = 'Upcoming Exhibition'
export const PAST_EXHIBITIONS_LABEL = 'Past Exhibition'

export const exhibitionsLabelMap = {
    CURRENT: CURRENT_EXHIBITIONS_LABEL,
    PAST: PAST_EXHIBITIONS_LABEL,
    UPCOMING: UPCOMING_EXHIBITIONS_LABEL,
}

export const CURRENT_ARTFAIRS_LABEL = 'Current Fair Booth'
export const UPCOMING_ARTFAIRS_LABEL = 'Upcoming Fair Booth'
export const PAST_ARTFAIRS_LABEL = 'Past Fair Booth'

export const fairsLabelMap = {
    CURRENT: CURRENT_ARTFAIRS_LABEL,
    PAST: PAST_ARTFAIRS_LABEL,
    UPCOMING: UPCOMING_ARTFAIRS_LABEL,
}
