import React, { useState, useRef, useEffect } from 'react'
import { Gallery } from '~/graphql/types'
import qs from 'querystring'
import styled from 'styled-components'
import { utils, CloseIcon } from '@artnetworldwide/library-ui'

const { mediaQueries } = utils

const iframeBaseUrl = process.env.PUBLIC_URL + '/inquiry-form-iframe.html'

const Iframe = styled.iframe`
    border: none;
    height: 700px;
    max-height: 100vh;
    width: 100%;

    @media ${mediaQueries.mediumUp} {
        width: 400px;
    }
`

const ForceCloseWrapper = styled.div`
    position: absolute;
    top: 11px;
    right: 13px;

    @media ${mediaQueries.mediumUp} {
        top: 4px;
    }
`

// Css used on art fairs. leaving commented here until we finish testing
// and ensure the new CSS is working in every case
//
// height: 775px;
// width: 100%;

interface Props {
    gallery: Partial<Gallery>
    onClose: () => void
    artworkId?: number
    // exhibition, art fair, or publication ID
    galleryItemId?: number
    artist?: string
}

export const InquiryForm: React.FC<Props> = ({
    gallery: { internalNumericId, id: galleryId },
    artworkId,
    galleryItemId,
    artist,
    onClose,
}) => {
    const iframe = useRef<HTMLIFrameElement | null>(null)

    const [inquiryFormLoaded, setInquiryFormLoaded] = useState(false)

    useEffect(() => {
        bindEvents()

        return () => {
            if (iframe.current) {
                iframe.current.removeEventListener('load', triggerIframeLoaded)
            }
        }
    })

    function triggerIframeLoaded() {
        setInquiryFormLoaded(true)
    }

    function bindEvents() {
        if (typeof window === 'undefined') {
            return
        }
        // we use requestAnimationFrame() so we don't try to access contentWindow before the iframe has finished rendering
        window.requestAnimationFrame(() => {
            if (iframe.current) {
                // listen for the load event so we can hide
                // our external close button once the iframe is ready
                iframe.current.addEventListener('load', triggerIframeLoaded)

                // this sets the onClose callback inside the iframe
                ;(iframe.current.contentWindow as any).onClose = onClose
            }
        })
    }

    let pagetype = 1 // default - general inquiry
    if (artworkId) {
        pagetype = 3
    } else if (artist) {
        pagetype = 14 // galleryArtist - a specific artist represented by a gallery
    } else if (galleryItemId) {
        pagetype = 7 // galleryItem / collection (meaning an exhibition, art fair, or publication)
    }

    const queryString = qs.stringify({
        // @NB galleryId is not needed by the server, this is used for tracking purposes within the iframe
        galleryId,
        memberSiteId: internalNumericId,
        artworkId,
        artist,
        // these parameters are just copied from the code for loading the inquiry form on the old gallery page
        collectionId: galleryItemId || 0,
        // this indicates either the general gallery inquiry form, the artwork-specific inquiry form,
        // or an inquiry for a
        pagetype,
        pageSection: galleryItemId ? 0 : 1,
    })

    return (
        <>
            {!inquiryFormLoaded && (
                <ForceCloseWrapper onClick={onClose}>
                    {/* stroke color is based off of the existing inquiry form close button */}
                    <CloseIcon height="13px" width="13px" stroke="#a8a8a8" />
                </ForceCloseWrapper>
            )}
            <Iframe
                className="inquiry-form-iframe"
                title={'inquiry-form-' + internalNumericId}
                ref={(el: any) => {
                    iframe.current = el as HTMLIFrameElement
                }}
                src={iframeBaseUrl + '?' + queryString}
            />
        </>
    )
}

export default InquiryForm
