import { fetchJson } from '~/utils/helpers'
import fetch from 'node-fetch'
import { RedisCacheAdapter } from '~/lib/cache/adapters/redis'
import { Cache } from '~/lib/cache/types'
;(global as any).fetch = fetch

export const MANIFEST_CACHE_KEY = 'globalHeader'

async function fetchCachedManifest(cache: Cache, key: string) {
    return await cache.get(key)
}

export async function fetchSyndicatedComponentsManifest(
    cacheAdapter: RedisCacheAdapter
) {
    const MANIFEST_REQUEST_URL =
        process.env.SYNDICATED_COMPONENTS_MANIFEST_ENDPOINT +
        '/ssr-manifest.json' +
        '?components=' +
        JSON.stringify([{ id: 'global-header' }])

    if (!cacheAdapter.isReady()) {
        // if we can't connect to redis for some reason, go ahead and fetch the manifest
        // from the API
        return fetchJson(MANIFEST_REQUEST_URL)
    }

    const cachedManifest = await fetchCachedManifest(
        cacheAdapter,
        MANIFEST_CACHE_KEY
    )

    return !cachedManifest
        ? fetchJson(MANIFEST_REQUEST_URL, {}, cacheAdapter)
        : cachedManifest
}
