import React from 'react'
import { BackToTop, MetaDataOpenGraph } from '~/components'
import {
    PageHeader,
    SectionHeader,
    Copy,
    Step,
    Picture,
    Block,
    ContactDetail,
} from './partials'

const ArtistAlerts: React.FC = () => {
    const metaData = {
        pageTitle: 'Artnet Artist Alerts',
        canonicalUrl: `https://www.artnet.com/help/artist-alerts`,
        description:
            "Interested in learning more about the artists you love? Introducing Artist Alerts: A free tool delivering customized email updates about all your favorite artists. Every weekday, you'll receive a message with new artworks by your selected artists available in galleries, auction houses, or online sales, along with artworks that have recently been added to the Artnet Price Database.",
        keywords: 'Artnet Artist Alerts, Follow, Follow artist on artnet',
        ogTitle: 'Artnet Artist Alerts',
        ogType: 'website',
        ogUrl: 'https://www.artnet.com/help/artist-alerts',
        ogImage:
            'https://storage.googleapis.com/artnet-interim-static-assets-repository/artist-alerts/Hero-Image_mobile.png',
    }
    return (
        <>
            <MetaDataOpenGraph {...metaData} routeChanged={true} />
            <PageHeader>Artnet Artists Alerts</PageHeader>

            <Block>
                <Picture
                    altText="Sign up for Artist alerts from Artnet"
                    largeImageUrl="https://storage.googleapis.com/artnet-interim-static-assets-repository/artist-alerts/Hero-Image_web.png"
                    mediumImageUrl="https://storage.googleapis.com/artnet-interim-static-assets-repository/artist-alerts/Hero-Image_mobile.png"
                />
                <Copy>
                    Interested in learning more about the artists you love?
                    Introducing Artist Alerts: A free tool delivering customized
                    email updates about all your favorite artists. Every
                    weekday, you&apos;ll receive a message with new artworks by
                    your selected artists available in galleries, auction
                    houses, or online sales, along with artworks that have
                    recently been added to the Artnet Price Database.
                </Copy>
            </Block>
            <Block>
                <SectionHeader>Follow Your Favorite Artists</SectionHeader>
                <Step indicator="1">
                    Navigate to &quot;artnet.com/artists&quot; and use the
                    search bar to find your favorite artist(s) that you&apos;d
                    like to follow.
                </Step>
                <Step indicator="2">
                    Once on your favorite artist&apos;s page (say, Pablo
                    Picasso, for example), click the black &quot;Follow&quot;
                    button that&apos;s located to the right of the artist name:
                </Step>
                <Picture
                    altText="Click follow button"
                    largeImageUrl="https://storage.googleapis.com/artnet-interim-static-assets-repository/artist-alerts/Text-Image-1_web.jpg"
                    mediumImageUrl="https://storage.googleapis.com/artnet-interim-static-assets-repository/artist-alerts/Text-Image-1_mobile.jpg"
                />
                <Step indicator="3">
                    You&apos;ll then be instructed to enter the email address
                    for which you&apos;d like to receive your daily Artist
                    Alerts. Please enter your email address and hit
                    &quot;Follow.&quot;
                </Step>
                <Picture
                    altText="Enter your email"
                    largeImageUrl="https://storage.googleapis.com/artnet-interim-static-assets-repository/artist-alerts/Text-Image-2_web.jpg"
                    mediumImageUrl="https://storage.googleapis.com/artnet-interim-static-assets-repository/artist-alerts/Text-Image-2_mobile.jpg"
                />
                <Step indicator="4">
                    Once hitting &quot;Follow,&quot; the below confirmation
                    notice will appear:{' '}
                </Step>
                <Picture
                    altText="Artist alerts confirmation"
                    largeImageUrl="https://storage.googleapis.com/artnet-interim-static-assets-repository/artist-alerts/Text-Image-3_web.jpg"
                    mediumImageUrl="https://storage.googleapis.com/artnet-interim-static-assets-repository/artist-alerts/Text-Image-3_mobile.jpg"
                />
                <Step indicator="5">
                    You will also receive a confirmation email to your inbox. As
                    a final step, please click through the link in this email to
                    confirm your address. Note that you will receive a total of
                    one email each day with information about your favorite
                    artist(s).
                </Step>
            </Block>

            <Block>
                <Copy>
                    If at any point in time you wish to no longer receive
                    information for any particular artist(s), please navigate to
                    his or her name at the top of your Artist Alert email and
                    click the &quot;Unfollow&quot; button to the right of the
                    artist&apos;s name.
                </Copy>
            </Block>
            <Block>
                <ContactDetail>
                    <h3>Contact</h3>
                    <h4>arnet</h4>
                    <p>Ning Lu</p>
                    <p>Vice President, Operations</p>
                    <p>Artnet Worldwide Corporation</p>
                    <p>+1-212-497-9700</p>
                    <em>support@artnet.com</em>
                    <BackToTop />
                </ContactDetail>
            </Block>
        </>
    )
}

export default ArtistAlerts
