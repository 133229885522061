import React from 'react'
import { LoadingOverlay } from '@artnetworldwide/library-ui'
import loadable from '@loadable/component'

const loadableOpts = { fallback: <LoadingOverlay /> }

export default {
    home: loadable(
        () => import(/* webpackChunkName: 'home' */ './pages/home'),
        loadableOpts
    ),
    galleries: loadable(
        () =>
            import(
                /* webpackChunkName: 'galleries' */ './pages/galleries/router'
            ),
        loadableOpts
    ),
}
