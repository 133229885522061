import React from 'react'
import styled from 'styled-components'
import { Footer } from './Footer'
import { Header } from './header/Header'
import { Content } from './Content'
import { utils } from '@artnetworldwide/library-ui'

export const Wrapper = styled.div`
    font-size: 14px;
    font-weight: 300;

    ul {
        li {
            margin: 0 15px 10px 0;
        }

        @media ${utils.mediaQueries.mediumUp} {
            padding-left: 25px;
        }
    }
`

export default function SimpleLayout({
    children,
}: {
    children: React.ReactNode
}) {
    return (
        <Wrapper>
            <Header />
            <Content>{children}</Content>
            <Footer />
        </Wrapper>
    )
}
