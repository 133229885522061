import React from 'react'
import Styled from 'styled-components'
import { utils } from '@artnetworldwide/library-ui'

export interface SVGProps {
    fill?: string
}
const SVGView = Styled.svg<SVGProps>`
    fill: ${({ fill, theme }) => (fill ? fill : theme.colors.brand)};
    height: 16px;
    width: auto;

    /* Desktop & tablet styles */
    @media ${utils.mediaQueries.mediumUp} {
        height: 31px;
    }
`
export function ArtnetLogo({ fill, ...rest }: SVGProps) {
    return (
        <SVGView viewBox="0 0 240.01 65.1" fill={fill} {...rest}>
            <path d="M48,56.5l.2,7.4a34.35,34.35,0,0,1-5.6.4q-8.25,0-9-7.8c-2.8,5.7-8.3,8.6-16.8,8.6S0,60.5,0,50.7C0,39.9,9.1,36.9,17,35.4l16.5-3.2c0-4.5-.2-7-1.7-9.1s-4.7-3.3-9.1-3.3C14,19.8,10.5,23,10.5,30.6L1.7,29.4c.2-6,2.3-10.4,6.2-13,3.6-2.4,8.9-3.7,15.3-3.7,12.6,0,19,5.6,19,16.5V53.1c0,2.6,0,3.8,2.9,3.8A12.29,12.29,0,0,0,48,56.5ZM33.5,38.9,18.4,42.2c-5.3,1.2-9.2,2.7-9.2,8.4,0,4.7,4.1,7.3,9,7.3,10.9,0,15.3-5.7,15.3-12.9ZM76.9,13.5c.8-.1,2.7-.1,3.4-.1l-.2,8.5a15.52,15.52,0,0,0-2.3-.1c-9.5,0-16.4,3-16.4,19.1v23h-9v-50h9v9.5C62.8,18.7,68.7,14.5,76.9,13.5Zm24.41.4h10.2v7.5h-10.2v31c0,3.3,1.5,3.9,5.2,3.9a30.3,30.3,0,0,0,4.6-.5l.2,8.1a32.13,32.13,0,0,1-5.5.5c-9.3,0-13.4-3.6-13.4-11.7V21.4h-7.2V13.9h7.2V0h8.9Zm55.2,4.4c3.8,4.1,3.9,10.6,3.9,15.9V63.9h-9.3V34.7c0-4.6.1-8.7-3-11.6-2.8-2.8-6.1-2.9-8.9-2.9-4.4,0-13,1.8-13,16.1V63.9h-9v-50h9v7.6c2.5-5.6,7.4-8.8,15.4-8.8C148.21,12.7,152.91,14.5,156.51,18.3Zm52.2,4.3c3,4.8,4.6,11.9,4.2,19H175c.2,11,4.8,16.3,14.2,16.3,8.5,0,13.1-3.6,14.3-11.2l8.7.7C210,59.1,202,65.1,189.31,65.1c-7.7,0-13.7-2.3-18-7.3-4.2-4.7-6.1-11.2-6.1-19,0-7.4,2.1-13.9,6.3-18.6,4.4-5,10.5-7.5,17.7-7.5C197.11,12.7,204.61,16.1,208.71,22.6Zm-31.2,2.8a17.82,17.82,0,0,0-2.4,8.9h28.6a19.22,19.22,0,0,0-2.7-8.9c-2.6-3.8-6.3-5.4-11.7-5.4C183.71,20,179.51,22.1,177.51,25.4Zm52.3-4v31c0,3.3,1.5,3.9,5.2,3.9a30.3,30.3,0,0,0,4.6-.5l.2,8.1a32.13,32.13,0,0,1-5.5.5c-9.3,0-13.4-3.6-13.4-11.7V21.4h-7.2V13.9h7.2V0h8.9V13.9H240v7.5Z" />
        </SVGView>
    )
}
