import React from 'react'
import styled from 'styled-components'
import { utils } from '@artnetworldwide/library-ui'

const MAIN_LINK_HEIGHT = 39

export const HeaderView = styled.header`
    box-sizing: border-box;
    color: ${p => p.theme.colors.dark};
    margin: 0 auto;
    margin: 20px 15px;
    max-width: 960px;

    @media ${utils.mediaQueries.mediumUp} {
        margin: 0 auto 0;
        padding: 0 0;
    }
`

export const GlobalLinks = styled.div`
    display: none;

    a {
        color: ${p => p.theme.colors.dark};
        margin: 0 10px;
        text-decoration: none;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    @media ${utils.mediaQueries.mediumUp} {
        border-bottom: 1px solid ${p => p.theme.colors.lightGray};
        display: flex;
        justify-content: flex-end;
        padding: 8px 0;
    }

    @media ${utils.mediaQueries.mediumOnly} {
        a {
            &:first-child {
                margin-left: 15px;
            }

            &:last-child {
                margin-right: 15px;
            }
        }
    }
`

export const LogoContainer = styled.div`
    position: relative;
    align-items: center;
    border-bottom: 1px solid ${p => p.theme.colors.lightGray};
    display: flex;
    justify-content: center;

    h1 {
        display: inline-block;
        line-height: 0;
        margin: 27px 0 27px;
    }

    @media ${utils.mediaQueries.mediumUp} {
        justify-content: space-between;

        h1 {
            margin: 28px 0 34px 34px;
        }
    }
`

export const MobileBurger = styled.button`
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    border: none;
    cursor: pointer;
    display: block;
    margin: 0;
    padding: 0;

    @media ${utils.mediaQueries.mediumUp} {
        display: none;
    }
`
export interface NavProps {
    toggle: boolean
}

export const MainNav = styled.nav<NavProps>`
    align-items: center;
    border-bottom: ${p =>
        p.toggle ? `1px solid ${p.theme.colors.lightGray}` : 'none'};
    display: flex;
    flex-direction: column;
    height: ${p =>
        p.toggle
            ? `${React.Children.count(p.children) * MAIN_LINK_HEIGHT}px`
            : '0px'};
    justify-content: center;
    overflow: hidden;
    transition: ${utils.easeOutQuad};

    /* stylelint-disable no-descending-specificity */
    a {
        border-bottom: 1px solid ${p => p.theme.colors.lightGray};
        box-sizing: border-box;
        display: block;
        margin: 0;
        padding: 10px 0;
        text-align: center;
        width: 100%;

        &:last-child {
            border-bottom: none;
        }
    }

    @media ${utils.mediaQueries.mediumUp} {
        border-bottom: 1px solid ${p => p.theme.colors.lightGray};
        display: none; /* Exception: there is no Main Navigation (page level); so hide it */
        flex-direction: row;
        height: 20px;
        padding-bottom: 30px;

        > a {
            border-bottom: none;
            display: inline;
            margin-right: 20px;
            padding: 0;
            width: auto;

            &:last-child {
                margin-right: 0;
            }
        }
    }
`
