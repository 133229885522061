import { constants } from '@artnetworldwide/library-ui'
import * as theme from '~/theme'

export * from './carousel'
export * from './grid'
export * from './enums'
export * from './events'
export * from './filters'

/**
 * CSS Values
 */
const {
    VERTICAL_SPACE: { by20, by40, by60 },
} = constants

const {
    colors: { lightGray },
} = theme

export const VERTICAL_SECTION_SPACING = by40

export const DESKTOP_MAIN_CONTENT_GUTTER = by60
export const MOBILE_MAIN_CONTENT_GUTTER = by20

export const SECTION_BORDER_STYLE = `1px solid ${lightGray}`

export const CONTENT_MAX_WIDTH = '1440px'

// custom media query to fit Price database in the navigation
export const NavMediaQuery =
    'screen and (min-width: 768px) and (max-width: 1200px)'
export const ProTabletPortrait =
    'screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait)'
export const TabletLandscape =
    'screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)'
