// TODO These messages should probably come from the CMS. But since this is currently a single-page app,
// we need something like this anyhow in the case of failure to connect to the API

export const defaultErrorHeading = 'Temporary Maintenance'

export const defaultErrorMessage = `
    <p>artnet is temporarily undergoing maintenance.</p>

    <p>Please try again shortly. We apologize for any inconvenience.</p>

    <p>For more information, please send us an email at<br/>
    <a href="mailto:support@artnet.com">support@artnet.com</a> or call us<br/>
    at +1-800-427-8638.
    </p>
`
