import { utils } from '@artnetworldwide/library-ui'
import React from 'react'
import styled from 'styled-components'
import { DESKTOP_MAIN_CONTENT_GUTTER, CONTENT_MAX_WIDTH } from '~/constants'

const { mediaQueries, buildClassName } = utils

const Wrapper = styled.main`
    display: block; /* needed for IE */
    margin: 0 auto;
    max-width: ${CONTENT_MAX_WIDTH};
    min-height: 100vh;

    @media ${mediaQueries.mediumUp} {
        padding: 0 ${DESKTOP_MAIN_CONTENT_GUTTER};
    }
`

interface Props {
    sectionName: string
}
export const Content = styled.div`
    min-height: 120vh;
`
export const Main: React.SFC<Props> = ({ children, sectionName, ...props }) => (
    <Wrapper
        className={`${buildClassName('Main')} ${buildClassName(
            sectionName + '_tab'
        )}`}
        {...props}
    >
        {children}
    </Wrapper>
)
