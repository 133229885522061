// @TODO This image config should come from graphql

export const globalDefaultImageSize = 'medium'

export const standardImageSourceSizes = {
    small: { width: 307, height: 219 },
    medium: { width: 420, height: 340 },
    mediumWide: { width: 758, height: 380 },
    large: { width: 645, height: 420 },
    largeWide: { width: 1320, height: 550 },
    extraLarge: { width: 1320, height: 1320 },
    profileImage: { width: 145, height: 145 },
    artworkGrid: { width: 307, height: 320 },
}

// We're not optimizing for retina screens yet, so for now these are the same as the source sizes
export const standardImageDisplaySizes = { ...standardImageSourceSizes }
