import styled from 'styled-components'
import { utils } from '@artnetworldwide/library-ui'

const { pixelsToRem, mediaQueries } = utils

export const Loader = styled.div`
    position: relative;
    padding: 24px 0;

    && svg {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 24px;
        transform: translate(-50%, -50%);
        width: 24px;
    }
`

export const CloseButton = styled.button`
    border: none;
    outline: none;
`
CloseButton.displayName = 'CloseButton'

export const StyledInput = styled.input`
    position: relative;
    appearance: none;
    background: transparent;
    border: 1px solid ${p => p.theme.colors.lightGray};
    border-radius: 0;
    box-sizing: border-box;
    font-size: ${pixelsToRem(12)};
    height: 31px;
    line-height: 31px;
    padding: 0 30px 0 12px;
    width: 100%;

    &:focus {
        outline: none;
    }
`

StyledInput.displayName = 'StyledInput'

export interface HintViewProps {
    top?: string
}

export const HintView = styled.div`
    position: absolute;
    top: ${({ top }: HintViewProps) => (top ? top : '30px')};
    background: ${p => p.theme.colors.white};
    border: 1px solid ${p => p.theme.colors.lightGray};
    list-style: none;
    text-align: left;
    width: 100%;
    z-index: 900;

    button {
        position: absolute;
        top: -24px;
        right: 16px;
        cursor: pointer;
    }

    && svg {
        height: 12px;
        width: 12px;
    }

    a {
        color: ${p => p.theme.colors.mediumGray};
        cursor: pointer;
        display: block;
        font-size: 12px;
        letter-spacing: 1.1px;
        line-height: 30px;
        margin-top: 0;
        overflow: hidden;
        padding: 0 12px 0 12px;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;

        &.term {
            color: #000;
        }

        &:hover {
            background: ${p => p.theme.colors.lightGray};
            color: ${p => p.theme.colors.dark};
        }
    }

    @media ${mediaQueries.mediumUp} {
        button {
            top: -17px;
            right: 30px;
        }
    }
`
HintView.displayName = 'HintView'
