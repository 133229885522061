import styled from 'styled-components'
import { utils } from '@artnetworldwide/library-ui'

export const Content = styled.main`
    box-sizing: border-box;
    display: block; /* fix for IE */
    font-size: 14px;
    line-height: 22px;
    min-height: 80vh;
    padding: 0 0 50px;
    width: 100%;

    @media ${utils.mediaQueries.mediumUp} {
        font-size: 16px;
        line-height: 24px;
        margin: 0 auto;
        max-width: 960px;
        padding: 0 0 50px;
    }
`
