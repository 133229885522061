import React, { PureComponent } from 'react'
import { Query } from '@apollo/react-components'
import {
    OperationVariables,
    ObservableQueryFields,
    QueryResult,
} from '@apollo/react-common'
import ApolloClient, {
    FetchPolicy,
    ErrorPolicy,
    NetworkStatus,
} from 'apollo-client'
import { DocumentNode } from 'graphql'
import { ErrorMessage } from '../ErrorMessage'
import { LoadingOverlay } from '@artnetworldwide/library-ui'

export interface QueryLoaderProps<
    TData = any,
    TVariables = OperationVariables
> {
    children?: (result: QueryLoaderResult<TData, TVariables>) => React.ReactNode
    component?: React.ComponentType<any>
    componentProps?: object
    fetchPolicy?: FetchPolicy
    errorPolicy?: ErrorPolicy
    notifyOnNetworkStatusChange?: boolean
    pollInterval?: number
    query?: DocumentNode
    variables?: TVariables
    ssr?: boolean
    displayName?: string
    skip?: boolean
    client?: ApolloClient<object>
    context?: Record<string, any>
}

export interface QueryLoaderResult<TData, TVariables = OperationVariables>
    extends ObservableQueryFields<TData, TVariables> {
    data: TData
    client: ApolloClient<any>
    networkStatus: NetworkStatus
}

/**
 * Base class for any query component that should have the default loading and error handling behavior.
 * This is a thin wrapper around Apollo's Query component.
 */
export class QueryLoader<
    TData = any,
    TVariables = OperationVariables
> extends PureComponent<QueryLoaderProps<TData, TVariables>> {
    protected static query: DocumentNode

    render() {
        const { children } = this.props
        const Component = this.props.component as React.ComponentType<any>
        if (!Component && !children) {
            throw Error(
                `Either 'component' prop or a render prop as children must be provided`
            )
        }
        if (Component && children) {
            throw Error(
                `This component accepts either a 'component' prop or children, but not both`
            )
        }
        const { query } = this.constructor as typeof QueryLoader
        return (
            <Query query={query} {...this.props}>
                {(result: any) =>
                    //{(result: QueryResult<TData, TVariables>) =>
                    queryRenderFunction<TData, TVariables>(this.props, result)
                }
            </Query>
        )
    }
}

// NOTE: This is only exported so that it can be used by the mock QueryLoader.
// It should not be used externally
export function queryRenderFunction<TData, TVariables>(
    props: QueryLoaderProps<TData, TVariables>,
    fullResult: QueryResult<TData, TVariables>
) {
    const { children, componentProps } = props
    const Component = props.component as React.ComponentType<any>

    // in our result, we return everything in Apollo's QueryResult except for `loading` and `error`,
    // since we're handling those here
    const { loading, error, ...result } = fullResult

    if (error) {
        if (!isProduction()) {
            console.error('Apollo error:', error)
        }
        return <ErrorMessage heading="Apollo Error" errorObject={error} />
    }
    if (loading) {
        return <LoadingOverlay />
    }
    if (!result.data) {
        // TODO: verify that we actually need to check for this. It might be an impossible case
        // (yes, Apollo's `data` prop is marked as nullable, but that might just be to account for the
        // fact that data can be undefined when `loading` is true or when there's an error, both of
        // which we already handled above)
        const missingDataErr = new Error(
            'QueryLoader: No data was returned by apollo-client'
        )
        if (!isProduction()) {
            console.error(missingDataErr)
        }
        return <ErrorMessage errorObject={missingDataErr} />
    }
    if (children) {
        return children(
            result as QueryLoaderResult<TData, TVariables>
        ) as React.ReactElement | null
    }
    // Otherwise, it's using the `component` prop...
    //
    // Note: `componentProps` is deliberately after `result` because there are a lot of props in `result`
    // and the programmer should be able to override them if they want in the case of prop name clashes.
    // For more precise control in the case of name clashes, use the render prop API and create your
    // React element manually, e.g. instead of this:
    //      <MyQuery component={MyComponent} componentProps={{foo: 'demo'}} />
    // You could do this:
    //      <MyQuery>
    //      {({data}) => (
    //          <MyComponent foo="demo" data={data} />
    //      )}
    //      </MyQuery>
    //
    return <Component {...result} {...componentProps} />
}

function isProduction() {
    return process.env.NODE_ENV === 'production'
}
