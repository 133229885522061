import styled, { css } from 'styled-components'
import { utils } from '@artnetworldwide/library-ui'

const { pixelsToRem, mediaQueries } = utils

interface MobileHeaderProps {
    isOpen: boolean
    contentHeight: number | null
}

const isOpenStyles = (props: MobileHeaderProps) => {
    if (props.isOpen && props.contentHeight) {
        return css`
            height: ${props.contentHeight}px;
            overflow: none;
        `
    }

    return css`
        height: 0;
        margin: 0;
        overflow: hidden;
    `
}

export const MobileNavList = styled.ul`
    display: flex;
    flex-direction: column-reverse;
    list-style: none;
    margin-bottom: 0;
    ${isOpenStyles};
    overflow: hidden;
    padding: 0;
    transition: 0.2s all;

    li {
        border-bottom: 1px solid #ddd;
        box-sizing: border-box;
        padding: 16px 0;
        text-align: center;
    }

    @media ${mediaQueries.mediumUp} {
        display: none;
    }
`
MobileNavList.displayName = 'MobileNavList'

export const MobileSearchWrapper = styled.div`
    position: relative;
    ${isOpenStyles};
    margin: ${p => (p.isOpen ? '20px 0' : 0)};
    transition: 0.2s all;

    @media ${mediaQueries.mediumUp} {
        display: none;
    }

    input {
        font-size: ${pixelsToRem(16)};
        height: 46px;
        padding-left: 32px;
    }

    svg {
        position: absolute;
        top: 50%;
        left: 8px;
        height: 18px;
        opacity: ${(props: MobileHeaderProps) => (props.isOpen ? 1 : 0)};
        transform: translateY(-50%);
        transition: opacity 0.3s;
        width: 18px;
        z-index: -1;
    }
`
MobileSearchWrapper.displayName = 'MobileSearchWrapper'

const MobileNavButton = styled.button`
    border: none;
    cursor: pointer;
    width: 24px;

    &:focus {
        outline: none;
    }

    @media ${mediaQueries.mediumUp} {
        display: none;
    }
`

export const MobileMenuButton = styled(MobileNavButton)`
    position: absolute;
    top: -75px;
    left: 0;
    height: 16px;
    padding: 0;
`
MobileMenuButton.displayName = 'MobileMenuButton'

export const MobileSearchButton = styled(MobileNavButton)`
    position: absolute;
    top: -75px;
    right: -2px;
    height: 24px;
    padding: 0;
`
MobileSearchButton.displayName = 'MobileSearchButton'
