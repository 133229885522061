import React from 'react'
import { Gallery } from '~/graphql/types'
import { Modal } from '@artnetworldwide/library-ui'
import { InquiryForm } from '.'

const Context = React.createContext({})

export interface InquiryModalProviderProps {
    gallery: Partial<Gallery>
}

interface InquiryModalProviderState {
    show: boolean
    openModal: (
        e?: React.SyntheticEvent<any>,
        inquiryProps?: InquiryProps
    ) => void
    closeModal: (e?: React.SyntheticEvent<any>) => void
    inquiryProps: InquiryProps
}

interface InquiryProps {
    artworkId?: number
    // exhibition, art fair, or publication ID
    galleryItemId?: number
    artist?: string
}

export class InquiryModalProvider extends React.Component<
    InquiryModalProviderProps,
    InquiryModalProviderState
> {
    state = {
        show: false,
        openModal: (
            e?: React.SyntheticEvent<any>,
            inquiryProps: InquiryProps = {}
        ) => {
            this.setState({ inquiryProps })
            this.toggleModal(e, true)
        },
        closeModal: (e?: React.SyntheticEvent<any>) =>
            this.toggleModal(e, false),
        inquiryProps: {},
    }

    private toggleModal = (
        e: React.SyntheticEvent<any> | undefined,
        show: boolean
    ) => {
        if (e) {
            e.preventDefault()
        }
        this.setState({ show })
    }

    render() {
        const {
            inquiryProps: { artworkId, galleryItemId, artist },
            closeModal,
        } = this.state as InquiryModalProviderState
        return (
            <Context.Provider value={this.state}>
                <>
                    {this.props.children}
                    <Modal
                        show={this.state.show}
                        showCloseIcon={false}
                        closeOnClickOutside={false}
                        onRequestClose={closeModal}
                        padding="0"
                        mobilePadding="0"
                    >
                        <InquiryForm
                            gallery={this.props.gallery}
                            artworkId={artworkId}
                            galleryItemId={galleryItemId}
                            artist={artist}
                            onClose={closeModal}
                        />
                    </Modal>
                </>
            </Context.Provider>
        )
    }
}

export interface InquiryModalConsumerProps {
    children: (args: InquiryModalProviderState) => React.ReactNode
}

export const InquiryModalConsumer: React.ComponentType<InquiryModalConsumerProps> =
    Context.Consumer
