import React from 'react'
import { DocumentNode } from 'graphql'
import { OperationVariables, QueryResult } from '@apollo/react-common'
import { useQuery, QueryHookOptions } from '@apollo/react-hooks'
import { QueryLoaderContext } from './context'

export interface QueryLoaderResult<TData, TVariables>
    extends Pick<
        QueryResult<TData, TVariables>,
        | 'startPolling'
        | 'stopPolling'
        | 'subscribeToMore'
        | 'updateQuery'
        | 'refetch'
        | 'variables'
        | 'fetchMore'
        | 'client'
        | 'networkStatus'
    > {
    data: TData
}

function useQueryLoaderContext() {
    const ctx = React.useContext(QueryLoaderContext)
    if (!ctx) {
        throw Error(
            'Could not find the QueryLoaderContext. Wrap the root component in a <QueryLoaderProvider>.'
        )
    }
    return ctx
}

type RenderCallback<TData, TVariables> = (
    result: QueryLoaderResult<TData, TVariables>
) => React.ReactElement<any> | null

export function useQueryLoader<TData = any, TVariables = OperationVariables>(
    query: DocumentNode,
    options?: QueryHookOptions
): (cb: RenderCallback<TData, TVariables>) => React.ReactElement<any> | null {
    const result = useQuery(query, options)
    const {
        defaultLoadingComponent,
        defaultErrorComponent,
    } = useQueryLoaderContext()
    if (
        process.env.NODE_ENV !== 'production' &&
        !(defaultLoadingComponent && defaultErrorComponent)
    ) {
        throw Error(
            'defaultLoadingComponent and defaultErrorComponent must be set prior to using the useQueryLoader() hook'
        )
    }
    return (renderCallback: RenderCallback<TData, TVariables>) =>
        render(
            result,
            renderCallback,
            defaultLoadingComponent,
            defaultErrorComponent
        )
}

function render(
    result: QueryResult<any, any>,
    renderCallback: RenderCallback<any, any>,
    LoadingComponent: React.ComponentType<any>,
    ErrorComponent: React.ComponentType<{
        heading?: string | React.ReactNode
        errorObject?: Error
    }>
) {
    if (result.loading) {
        return <LoadingComponent />
    }
    if (result.error) {
        if (process.env.NODE_ENV !== 'production') {
            console.error('Apollo error:', result.error)
        }
        return (
            <ErrorComponent heading="Apollo Error" errorObject={result.error} />
        )
    }
    return renderCallback(result)
}
