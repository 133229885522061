import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ErrorMessage } from './components'
import SimpleLayout from './components/layout/simple'
import { LoadingOverlay } from '@artnetworldwide/library-ui'
import { QueryLoaderProvider } from './hooks/queryLoader'
import config from './config'
import { ThemeProvider } from 'styled-components'
import * as theme from './theme'
import { isPreRenderedOnServer } from '~/environment'
import NotFound from './pages/NotFound'
import pages from './dynamicPageImports'
import ArtistAlerts from './pages/help/ArtistAlerts'
import { SyndicatedComponentsManifest } from './components/layout/utils'

interface AppProps {
    syndicatedComponentsManifest?: SyndicatedComponentsManifest
}

interface AppState {
    syndicatedComponentsManifestForSPA: SyndicatedComponentsManifest | undefined
}

class App extends React.Component<AppProps, AppState> {
    state = {
        syndicatedComponentsManifestForSPA: undefined,
    }

    /**
     * @NB this is called so that we can inject the copy and pasted
     * Google Tag Manager script onto our page early in the app life cycle
     * @see https://support.google.com/tagmanager/answer/6103696?hl=en
     */
    constructor(props: AppProps) {
        super(props)

        // bail if we're not in browser env
        if (isPreRenderedOnServer()) {
            return
        }

        // // bail if we're not in browser env
        // // @NB required for rendering App component in tests
        // if (isPreRenderedOnServer() || process.env.NODE_ENV === 'test') {
        //     return
        // }

        // initialize data layer
        const windowRef = window as any
        windowRef.dataLayer = (windowRef.dataLayer as any[]) || []
        windowRef.dataLayer.push({
            userAgent: window.navigator.userAgent,
        })
        ;(function(w, d, s, l, i) {
            w[l] = w[l] || []
            w[l].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js',
            })
            const f = d.getElementsByTagName(s)[0]
            const j = d.createElement(s)
            const dl = l !== 'dataLayer' ? '&l=' + l : ''
            // @ts-ignore
            j.async = true
            // @ts-ignore
            j.src =
                'https://www.googletagmanager.com/gtm.js?id=' +
                i +
                dl +
                `&gtm_auth=${config.GTM_AUTH}&gtm_preview=${config.GTM_PREVIEW}&gtm_cookies_win=x`
            // @ts-ignore
            f.parentNode.insertBefore(j, f)
        })(window, document, 'script', 'dataLayer', config.GTM_CONTAINER_ID)
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <QueryLoaderProvider
                    options={{
                        defaultErrorComponent: ErrorMessage,
                        defaultLoadingComponent: LoadingOverlay,
                    }}
                >
                    <div id="app__container">
                        <Switch>
                            <Route path="/help/artist-alerts">
                                <SimpleLayout>
                                    <ArtistAlerts />
                                </SimpleLayout>
                            </Route>
                            <Route
                                render={() => (
                                    <Switch>
                                        <Route
                                            path="/"
                                            exact
                                            component={pages.home}
                                        />
                                        <Route
                                            path="/galleries/:galleryId/:gallerySection?/:pageNumber?"
                                            component={pages.galleries}
                                        />
                                        <Route component={NotFound} />
                                    </Switch>
                                )}
                            />
                        </Switch>
                    </div>
                </QueryLoaderProvider>
            </ThemeProvider>
        )
    }
}

export default App
